import { useRef } from 'react'

import useDebouncedEffect from '@hooks/useDebouncedEffect'
import { useSegmentTrack } from './useSegmentAnalytics'

export const useTrackPageView = (properties?: object, options?: { skip: boolean }) => {
  const track = useSegmentTrack()
  const propertiesRef = useRef(properties)

  useDebouncedEffect(() => {
    if (!options?.skip) track('pageView', propertiesRef.current)
  }, [track, options?.skip])
}

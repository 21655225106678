import dynamic from 'next/dynamic'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useGlobalContext } from '@context/GlobalContexts'
import { PlayerControls } from '@desktop/video-player/PlayerControls'
import { Transition } from '@headlessui/react'
import { useMobileLayout } from '@hooks/useMobileLayout'
import { usePrevious } from '@hooks/usePrevious'
import { IVideoPlayer } from '@hooks/useVideoPlayer'
import { useVideoPlayerControls } from '@hooks/useVideoPlayerControls'
import { useVideoPlayerEvents } from '@hooks/useVideoPlayerEvents'
import { useVideoPlayerKeyboard } from '@hooks/useVideoPlayerKeyboard'
import { useVideoSource } from '@hooks/useVideoSource'
import IconPlay from '@icons/IconPlayVideo.svg'
import { resolveMedia } from '@lib/mediaResolver'
import { globals } from '@lib/messages/protobuf'
import { IntoUrl } from '@models/IntoUrl'
import { setHasInteracted } from '@redux/slices/videoSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import { sendTrackContentPause } from '@tracking/events/trackContentPause'
import { sendTrackContentPlay } from '@tracking/events/trackContentPlay'
import { useTrackContentTimePlayed } from '@tracking/events/useTrackContentTimePlayed'
import { useCurrentUrlContext } from '../CurrentUrlContext'
import { useVideoPlayerContext } from './VideoPlayerContext'

export const CHROME_TABLET_THRESHOLD = 0.95
export const DEFAULT_THRESHOLD = 1

const ReactPlayer = dynamic(() => import('@desktop/video-player/ReactPlayerWrapper'), { ssr: false })

export const STRONG_GRADIENT_PROVIDERS = ['youtube', 'soundcloud']
const MAX_PROGRESS_PERCENTAGE = 100
const SECOND_TO_MS = 1000
const PROGRESS_INTERVAL = 16 // 60fps

const UrlVideo = ({
  videoPlayer,
  isPlayerReady,
  setIsPlayerReady,
  setMaxProgressPercentage,
  url,
}: {
  videoPlayer: IVideoPlayer
  isPlayerReady: boolean
  setIsPlayerReady: React.Dispatch<React.SetStateAction<boolean>>
  url: IntoUrl
  setMaxProgressPercentage: React.Dispatch<React.SetStateAction<number>>
}) => {
  const { isMuted, isAutoMuted } = useAppSelector(state => state.video)
  const {
    browserInfo: { browser },
  } = useGlobalContext()
  const { currentUserID: userId } = useCurrentUserContext()
  const { perceivedMediaType } = useCurrentUrlContext()
  const { isModalOpen } = useGlobalContext()
  const previousPlayed = useRef(0)
  const [loops, setLoops] = useState(0)
  const { videoUrl, provider } = resolveMedia(url)
  const shouldRenderMobile = useMobileLayout()
  const dispatch = useAppDispatch()

  const videoSourceUrl = useVideoSource({ videoUrl, provider })

  const { playerRef, disableCustomControls } = videoPlayer
  const wrapperRef = useRef<HTMLDivElement>(null)

  const { playing, played, duration, currentTime, seeking, setPlaying } = useVideoPlayerContext()

  const { onPlayPause, onPlay, onMute, onPause, onSeekChange, onSeekMouseDown, onSeekMouseUp, onEnded } =
    useVideoPlayerControls(playerRef)
  const { onProgress, onError, onDuration, onBuffer, onBufferEnd, onMouseEnter, onMouseLeave, onInteraction } =
    useVideoPlayerEvents()

  // Ready callback
  const onReady = useCallback(() => {
    setIsPlayerReady(true)
  }, [setIsPlayerReady])

  // Tracking and other effects
  const handleTrackContentPlay = useCallback(() => {
    sendTrackContentPlay({
      userId,
      url,
      perceivedMediaType,
      contentTimeTotalMsec: duration * SECOND_TO_MS,
      replayCount: loops,
      timeOffsetMsec: currentTime * SECOND_TO_MS,
    })
  }, [userId, url, perceivedMediaType, duration, loops, currentTime])

  const handleTrackContentPause = useCallback(() => {
    sendTrackContentPause({
      userId,
      url,
      perceivedMediaType,
      contentTimeTotalMsec: duration * SECOND_TO_MS,
      replayCount: loops,
      timeOffsetMsec: currentTime * SECOND_TO_MS,
    })
  }, [userId, url, perceivedMediaType, duration, loops, currentTime])

  const [userPaused, setUserPaused] = useState(false)

  // Modify the onTogglePlayAndTrack function
  const onTogglePlayAndTrack = useCallback(() => {
    if (playing) {
      handleTrackContentPause()
      setUserPaused(true)
    } else {
      handleTrackContentPlay()
      setUserPaused(false)
    }

    onPlayPause()
  }, [playing, handleTrackContentPlay, handleTrackContentPause, onPlayPause])

  // Handle play event
  const handlePlay = useCallback(() => {
    setPlaying(true)
    onPlay()
  }, [setPlaying, onPlay])

  // Modify the handlePause function
  const handlePause = useCallback(() => {
    if (!userPaused) {
      setPlaying(false)
      onPause()
    }
    setUserPaused(false)
  }, [onPause, userPaused, setPlaying])

  useVideoPlayerKeyboard(onMute, onTogglePlayAndTrack, playerRef, duration)

  // Effect for progress percentage
  useEffect(() => {
    if (loops > 0) {
      setMaxProgressPercentage(MAX_PROGRESS_PERCENTAGE)
    } else {
      const calculatedMaxProgress = played * MAX_PROGRESS_PERCENTAGE
      setMaxProgressPercentage(calculatedMaxProgress)
    }
  }, [loops, played, setMaxProgressPercentage])

  // Effect for loops
  useEffect(() => {
    if (!seeking && played < previousPlayed.current) {
      setLoops((prev: number) => prev + 1)
    }
    previousPlayed.current = played
  }, [played, seeking])

  const prevIsModalOpen = usePrevious(isModalOpen)
  const lastPlayingState = useRef(playing)

  // Handle video playback when modal opens/closes
  useEffect(() => {
    if (isModalOpen && !prevIsModalOpen && playing) {
      onPause()
    } else if (!isModalOpen && prevIsModalOpen && !playing && lastPlayingState.current) {
      onPlay()
    }

    // Update the last playing state when not affected by modal changes
    if (!isModalOpen) lastPlayingState.current = playing
  }, [isModalOpen, prevIsModalOpen, playing, onPause, onPlay])

  useTrackContentTimePlayed({
    userId,
    url,
    perceivedMediaType,
    contentTimeTotalMsec: duration * SECOND_TO_MS,
    timeSpentMsec: currentTime * SECOND_TO_MS,
    replayCount: loops,
    isPlayable: true,
    playerRef,
    isPaused: !playing,
  })

  return (
    <>
      <div
        data-testid="video-player"
        className={[
          `absolute left-0 top-0 size-full ${shouldRenderMobile ? 'bg-transparent' : 'bg-black'}`,
          isPlayerReady ? '' : 'pointer-events-none',
        ].join(' ')}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onInteraction}
        onTouchMove={onInteraction}
        onTouchStart={onInteraction}
        onTouchEnd={onInteraction}
      >
        <div ref={wrapperRef} className="flex size-full items-center justify-center">
          <ReactPlayer
            playerRef={playerRef}
            url={videoSourceUrl || videoUrl}
            playing={playing}
            loop={provider !== 'soundcloud'}
            width={'100%'}
            height={'100%'}
            onClickPreview={() => dispatch(setHasInteracted(true))}
            muted={isMuted || isAutoMuted}
            playsinline={true}
            onProgress={onProgress}
            onDuration={onDuration}
            progressInterval={PROGRESS_INTERVAL}
            onBuffer={onBuffer}
            onBufferEnd={onBufferEnd}
            onReady={onReady}
            onPlay={handlePlay}
            stopOnUnmount={true}
            onPause={handlePause}
            onError={onError}
            onEnded={onEnded}
            className={`absolute left-0 top-0 size-full ${isPlayerReady ? 'z-10 opacity-100' : 'z-10'}`}
            controls={disableCustomControls}
            config={{
              youtube: YOUTUBE_CONFIG,
              soundcloud: SOUNDCLOUD_CONFIG,
              file: {
                // React-player only disables hls.js in iOS and iPadOS.
                // However, desktop safari also natively supports HLS and we should use that over hls.js.
                forceDisableHls: browser.safari,
                // The version has been pinned intentionally.
                // Context: https://app.asana.com/0/0/1205619255343683
                hlsVersion: '0.14.7',
                forceHLS: false,
                attributes: { poster: url.thumbnail ?? url.tiny_thumbnail },
              },
            }}
          />
          {!disableCustomControls && (
            <>
              {!shouldRenderMobile && (
                <div className="absolute inset-0 z-20 size-full" onClick={onTogglePlayAndTrack}>
                  <Transition
                    show={!playing && isPlayerReady}
                    enter="transition-opacity duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div
                      className={`pointer-events-auto absolute inset-0 flex items-center justify-center ${
                        STRONG_GRADIENT_PROVIDERS.includes(provider) ? 'bg-strong-gradient' : 'bg-light-gradient'
                      }`}
                    >
                      <div className="rounded-full p-4">
                        <IconPlay className="size-16 text-white opacity-70 md:size-24" />
                      </div>
                    </div>
                  </Transition>
                </div>
              )}
              {isPlayerReady && (
                <PlayerControls
                  wrapperRef={wrapperRef}
                  isGIF={perceivedMediaType === globals.MediaType.GIF}
                  playerRef={playerRef}
                  onTogglePlayAndTrack={onTogglePlayAndTrack}
                  onPlayPause={onPlayPause}
                  onInteraction={onInteraction}
                  onSeekMouseDown={onSeekMouseDown}
                  onSeekChange={onSeekChange}
                  onSeekMouseUp={onSeekMouseUp}
                  onMute={onMute}
                  isPlayerReady={isPlayerReady}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export const YOUTUBE_CONFIG = {
  playerVars: {
    color: 'red',
    disablekb: 1,
    rel: 0,
    modestbranding: 1,
    fs: 0,
    iv_load_policy: 3,
  },
  embedOptions: {
    frameborder: '0',
  },
}

const SOUNDCLOUD_CONFIG = {
  options: {
    visual: true,
    buying: false,
    sharing: false,
    download: false,
    show_comments: false,
    show_playcount: false,
    show_user: false,
    show_reposts: false,
    show_teaser: false,
    show_user_playlist: false,
    single_active: false,
    hide_related: true,
    auto_play: true,
    show_bpm: false,
  },
}

export default UrlVideo

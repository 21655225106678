import { useEffect, useState } from 'react'

import logger from '@lib/logger'
import { APIErrorResponse } from '@models/APIErrorResponse'
import { TikTokVideoResponse } from '@pages/api/videos/tiktok.api'
import MixWeb from '@services/MixWeb'
import { useRequest } from './useRequest'

interface UseVideoSourceProps {
  videoUrl: string
  provider: string
}

export const useVideoSource = ({ videoUrl, provider }: UseVideoSourceProps): string => {
  const [videoSourceUrl, setVideoSourceUrl] = useState<string>(videoUrl)

  const tikTokRegex = /tiktok\.com\/.*\/video\/(\d+)/
  const match = tikTokRegex.exec(videoSourceUrl)

  const tiktokId = match ? match[1] : null

  const apiRequest = tiktokId && provider === 'tiktok' ? MixWeb.providers.tiktok.getVideoUrl({ id: tiktokId }) : null

  const { data, error } = useRequest<TikTokVideoResponse, APIErrorResponse>(apiRequest)

  if (error) {
    logger.warn('Failed to get video source url')
  }

  if (data?.videoSrc && provider === 'tiktok') {
    setVideoSourceUrl(data.videoSrc)
  }

  useEffect(() => {
    if (videoUrl !== videoSourceUrl) {
      setVideoSourceUrl(videoUrl)
    }
  }, [videoUrl, videoSourceUrl])

  return videoSourceUrl
}

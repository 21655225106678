import Long from 'long'
import { useCallback, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

import { getVideoElement } from '@hooks/useAudioPresence'
import useDebouncedEffect from '@hooks/useDebouncedEffect'
import { commands, globals } from '@lib/messages/protobuf'
import { IntoUrl } from '@models/IntoUrl'
import MixMix from '@services/MixMix'
import { broadcastTrackingMessage } from '../broadcast/trackingMessage'

import CommandContext = commands.CommandContext

const { TrackContentTimePlayed } = commands

interface IUseTrackContentTimePlayed {
  userId: number | undefined
  url: IntoUrl
  replayCount: number
  contentTimeTotalMsec: number
  perceivedMediaType: globals.MediaType
  timeSpentMsec: number
  isPlayable: boolean
  playerRef: React.RefObject<ReactPlayer>
  isPaused: boolean
}

export const useTrackContentTimePlayed = ({
  userId,
  url,
  perceivedMediaType,
  contentTimeTotalMsec,
  timeSpentMsec,
  replayCount,
  isPlayable,
  playerRef,
  isPaused,
}: IUseTrackContentTimePlayed) => {
  const { url_id: urlId } = url

  const handleContentTimePlayed = useCallback(() => {
    if (!urlId) return
    if (!isPlayable) return

    const createMessage = (context: CommandContext) => {
      const hasDuration = Number.isFinite(contentTimeTotalMsec)
      return new TrackContentTimePlayed({
        urlId: Long.fromString(urlId),
        userId: userId ? Long.fromNumber(userId) : null,
        timeSpentMsec: Long.fromNumber(timeSpentMsec),
        autoplay: true,
        replayCount,
        contentTimeTotalMsec: hasDuration ? Long.fromNumber(Math.trunc(contentTimeTotalMsec)) : null,
        perceivedMediaType,
        context,
      })
    }

    const initialConfig = MixMix.commands.trackContentTimePlayed({})
    void broadcastTrackingMessage('TrackContentTimePlayed', initialConfig, createMessage).catch(() => {})
  }, [userId, urlId, contentTimeTotalMsec, replayCount, timeSpentMsec, perceivedMediaType, isPlayable])

  const [hasEventFired, setHasEventFired] = useState(false)

  useDebouncedEffect(() => {
    const player = getVideoElement(playerRef)

    const handlePause = (event: Event) => {
      // If the user is switching tabs, we want to track the content time played when the browser automatically pauses the video

      // user switches tabs -> browser automatically pauses video
      if (event.type === 'pause' && document.hidden) {
        handleContentTimePlayed()
      }
    }

    player?.addEventListener('pause', handlePause)

    // user closes tab
    window.addEventListener('beforeunload', handleContentTimePlayed)

    return () => {
      window.removeEventListener('beforeunload', handleContentTimePlayed)
      player?.removeEventListener('pause', handlePause)
    }
  }, [handleContentTimePlayed, playerRef])

  useDebouncedEffect(() => {
    // If the user pauses the video and then plays it again, we want to reset the hasEventFired state. This reenables the visibilitychange and blur event listeners to fire the handleContentTimePlayed function
    const player = getVideoElement(playerRef)
    const handlePlayEvent = () => {
      if (hasEventFired) {
        setHasEventFired(false)
      }
    }
    player?.addEventListener('play', handlePlayEvent)
    return () => {
      player?.removeEventListener('play', handlePlayEvent)
    }
  }, [playerRef, hasEventFired])

  useDebouncedEffect(() => {
    // If the user switches tabs or blurs the window and previously paused the video, we want to track the content time played. We also want to set the hasEventFired state to true so that the handleContentTimePlayed function is not called again until a new play event is fired

    // user pauses video -> document.hidden
    const handleVisibilityChange = () => {
      if (document.hidden && isPaused && !hasEventFired) {
        handleContentTimePlayed()
        setHasEventFired(true)
      }
    }

    // user pauses video -> window.blur
    const handleBlurChange = () => {
      if (isPaused && !hasEventFired) {
        handleContentTimePlayed()
        setHasEventFired(true)
      }
    }

    window.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('blur', handleBlurChange)

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('blur', handleBlurChange)
    }
  }, [handleContentTimePlayed, isPaused, hasEventFired])

  const handleContentTimePlayedRef = useRef(handleContentTimePlayed)

  useDebouncedEffect(() => {
    handleContentTimePlayedRef.current = handleContentTimePlayed
  }, [handleContentTimePlayed])

  useDebouncedEffect(() => {
    // Unmounting the component should always track the content time played
    return () => {
      handleContentTimePlayedRef.current()
    }
  }, [])
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'

import { useProgressBar } from '@context/ProgressBarContext'
import { Transition } from '@headlessui/react'
import { useMobileLayout } from '@hooks/useMobileLayout'
import IconExitFullScreen from '@icons/IconExitFullScreen.svg'
import IconFullScreen from '@icons/IconFullScreen.svg'
import IconGIF from '@icons/IconGIF.svg'
import IconPause from '@icons/IconPause.svg'
import IconPlay from '@icons/IconPlayVideo.svg'
import IconSpeaker from '@icons/IconSpeaker.svg'
import IconUnmute from '@icons/IconUnmute.svg'
import { formatTime } from '@lib/date'
import { useVideoPlayerContext } from '@pages/url/components/VideoPlayerContext'
import { useAppSelector } from '@redux/store/store'

const PROGRESS_PRECISION = 2 // Decimal precision for progress percentage
const PERCENT_CONVERSION_FACTOR = 100 // Factor to convert fraction to percentage

interface PlayerControlsProps {
  // Playback states
  isPlayerReady: boolean

  // Volume & muting states
  isGIF: boolean

  // Playback callbacks
  onPlayPause: () => void
  onTogglePlayAndTrack: () => void
  onSeekMouseDown: (e: React.MouseEvent) => void
  onSeekChange: (time: number) => void
  onSeekMouseUp: (e: React.MouseEvent) => void

  // Volume & muting callbacks
  onMute: () => void

  // Miscellaneous UI
  playerRef: React.RefObject<ReactPlayer>
  wrapperRef: React.RefObject<HTMLDivElement>
  onInteraction: () => void
}

const transitionProps = {
  enter: 'transition-opacity duration-300',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'transition-opacity duration-300',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
}

const VOLUME_SLIDER_DELAY = 1000

export const PlayerControls: React.FC<PlayerControlsProps> = ({
  // Playback states
  playerRef,
  wrapperRef,
  isPlayerReady,
  // Volume & muting states
  isGIF,

  // Playback callbacks
  onPlayPause,
  onTogglePlayAndTrack,
  onSeekChange,

  // Volume & muting callbacks
  onMute,

  // Miscellaneous UI
  onInteraction,
}) => {
  const {
    playing,
    played,
    duration,
    currentTime,
    isHovered,
    lastInteractionTime,
    seeking,
    setSeeking,
    isGrabbing,
    setWasPlayingBeforeSeeking,
    wasPlayingBeforeSeeking,
    setDidPauseForDrag,
    didPauseForDrag,
    setIsGrabbing,
  } = useVideoPlayerContext()
  const { isMuted, hasEverUnmuted } = useAppSelector(state => state.video)
  const [isProgressBarHovered, setIsProgressBarHovered] = React.useState(false)
  const [, setIsTouchSeeking] = React.useState(false)
  const shouldRenderMobile = useMobileLayout()

  const progressBarRef = useRef<HTMLDivElement | null>(null)
  const progress = (played * PERCENT_CONVERSION_FACTOR).toFixed(PROGRESS_PRECISION) + '%'

  const { setProgressBar, clearProgressBar } = useProgressBar()

  const onSeekMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!seeking || !progressBarRef.current) return
      onInteraction()
      if (playing && !didPauseForDrag) {
        setDidPauseForDrag(true)
        setWasPlayingBeforeSeeking(true)
        onPlayPause()
      }
      const progressBar: HTMLElement = progressBarRef.current
      const rect = progressBar.getBoundingClientRect()
      const clickX = event.clientX - rect.left
      let newPlayedValue = clickX / rect.width

      // Clamp the value between 0 and 1
      newPlayedValue = Math.min(Math.max(newPlayedValue, 0), 1)

      const newTime = newPlayedValue * duration
      onSeekChange(newTime)

      if (playerRef.current) {
        if (Number.isFinite(duration) && duration > 0) {
          playerRef.current.seekTo(newTime / duration, 'fraction')
        } else {
          // Fallback to using newTime directly if duration is not valid.
          playerRef.current.seekTo(newTime, 'seconds')
        }
      }
    },
    [
      seeking,
      duration,
      onSeekChange,
      didPauseForDrag,
      playing,
      onPlayPause,
      setDidPauseForDrag,
      setWasPlayingBeforeSeeking,
      onInteraction,
      playerRef,
    ]
  )

  const onSeekTouchMove = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      if (!progressBarRef.current) return
      event.stopPropagation()
      if (!shouldRenderMobile) event.preventDefault()
      onInteraction()
      if (playing && !didPauseForDrag) {
        setDidPauseForDrag(true)
        setWasPlayingBeforeSeeking(true)
        onPlayPause()
      }
      const progressBar: HTMLElement = progressBarRef.current
      const rect = progressBar.getBoundingClientRect()
      const touchX = event.touches[0].clientX - rect.left
      let newPlayedValue = touchX / rect.width

      // Clamp the value between 0 and 1
      newPlayedValue = Math.min(Math.max(newPlayedValue, 0), 1)

      const newTime = newPlayedValue * duration
      onSeekChange(newTime)

      if (playerRef.current) {
        if (Number.isFinite(duration) && duration > 0) {
          playerRef.current.seekTo(newTime / duration, 'fraction')
        } else {
          // Fallback to using newTime directly if duration is not valid.
          playerRef.current.seekTo(newTime, 'seconds')
        }
      }
    },
    [
      duration,
      onSeekChange,
      didPauseForDrag,
      playing,
      onPlayPause,
      setDidPauseForDrag,
      setWasPlayingBeforeSeeking,
      onInteraction,
      playerRef,
      shouldRenderMobile,
    ]
  )

  const handleProgressBarInteraction = useCallback(
    (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
      event.stopPropagation()

      if (!progressBarRef.current) return
      onInteraction()
      const progressBar: HTMLElement = progressBarRef.current
      const rect = progressBar.getBoundingClientRect()
      let clickX

      if ('clientX' in event) {
        clickX = event.clientX - rect.left
      } else {
        clickX = event.changedTouches[0].clientX - rect.left
      }

      let newPlayedValue = clickX / rect.width
      // Clamp the value between 0 and 1
      newPlayedValue = Math.min(Math.max(newPlayedValue, 0), 1)
      const newTime = newPlayedValue * duration
      if (didPauseForDrag) {
        setDidPauseForDrag(false)
      }

      if (wasPlayingBeforeSeeking) {
        setWasPlayingBeforeSeeking(false)
        onPlayPause()
      }
      setIsGrabbing(false)
      setSeeking(false)
      onSeekChange(newTime)

      if (playerRef.current) {
        if (Number.isFinite(duration) && duration > 0) {
          playerRef.current.seekTo(newTime / duration, 'fraction')
        } else {
          // Fallback to using newTime directly if duration is not valid.
          playerRef.current.seekTo(newTime, 'seconds')
        }
      }
    },
    [
      duration,
      onInteraction,
      didPauseForDrag,
      wasPlayingBeforeSeeking,
      onPlayPause,
      onSeekChange,
      playerRef,
      setDidPauseForDrag,
      setIsGrabbing,
      setSeeking,
      setWasPlayingBeforeSeeking,
    ]
  )

  const onSeekStart = useCallback(
    (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
      event.stopPropagation()
      if ('touches' in event && !shouldRenderMobile) event.preventDefault()
      onInteraction()
      setSeeking(true)
      setIsGrabbing(true)
    },
    [onInteraction, setSeeking, setIsGrabbing, shouldRenderMobile]
  )

  const onSeekEnd = useCallback(
    (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
      if ('touches' in event) {
        event.stopPropagation()
        event.preventDefault()
      }
      setIsTouchSeeking(false)
      setSeeking(false)
      setIsGrabbing(false)
      handleProgressBarInteraction(event)
    },
    [setIsTouchSeeking, setSeeking, setIsGrabbing, handleProgressBarInteraction]
  )

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (seeking && progressBarRef.current) onSeekMouseMove(event)
    }

    const handleMouseUp = (event: MouseEvent) => {
      if (seeking && progressBarRef.current) onSeekEnd(event as unknown as React.MouseEvent<HTMLDivElement>)
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [seeking, onSeekMouseMove, onSeekEnd])

  const [mounted, setMounted] = useState(false)
  const isLiveStream = !(Number.isFinite(currentTime) && Number.isFinite(duration))
  const showPlayerControls = mounted && ((isHovered && !shouldRenderMobile) || lastInteractionTime !== null)
  const showPlayPauseButton = (isHovered && !shouldRenderMobile) || (showPlayerControls && !shouldRenderMobile)
  const showTimeDisplay =
    !isLiveStream &&
    ((isHovered && !shouldRenderMobile) || (showPlayerControls && !shouldRenderMobile && !isLiveStream))
  const showProgressBar = showPlayerControls && !isLiveStream
  const showVolumeControls = mounted && (showPlayerControls || (!hasEverUnmuted && !isGIF))

  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, VOLUME_SLIDER_DELAY)
  }, [])

  const formattedCurrentTime = isLiveStream ? undefined : formatTime(currentTime)
  const formattedDuration = isLiveStream ? undefined : formatTime(duration)

  const handleFullScreen = useCallback(() => {
    if (wrapperRef.current) {
      if (screenfull.isFullscreen) void screenfull.exit()
      else void screenfull.request(wrapperRef.current)
    }
  }, [wrapperRef])

  const progressBarContent = useMemo(
    () => (
      <div
        ref={progressBarRef}
        onClick={handleProgressBarInteraction}
        onMouseDown={onSeekStart}
        onTouchStart={onSeekStart}
        onTouchMove={onSeekTouchMove}
        onTouchEnd={onSeekEnd}
        onMouseEnter={() => setIsProgressBarHovered(true)}
        onMouseLeave={() => setIsProgressBarHovered(false)}
        className={`${shouldRenderMobile ? 'absolute -bottom-4 h-8 bg-transparent' : 'relative h-1 bg-white/20'} flex w-full cursor-pointer items-center`}
      >
        <div
          style={{ width: progress }}
          className={`absolute left-0 top-1/2 -translate-y-1/2 bg-white ${shouldRenderMobile ? 'h-[2px]' : 'h-1'}`}
        ></div>
        {(isGrabbing || isProgressBarHovered || seeking) && (
          <div
            style={{ left: `calc(${progress} - 8px)` }}
            className={`absolute top-1/2 size-4 -translate-y-1/2 rounded-full bg-white ${
              isGrabbing ? 'cursor-grabbing' : 'cursor-grab'
            }`}
          ></div>
        )}
      </div>
    ),
    [
      shouldRenderMobile,
      progress,
      isGrabbing,
      isProgressBarHovered,
      seeking,
      handleProgressBarInteraction,
      onSeekStart,
      onSeekTouchMove,
      onSeekEnd,
    ]
  )

  const setProgressBarIfNeeded = useCallback(() => {
    if (shouldRenderMobile) {
      if (isPlayerReady) {
        setProgressBar(progressBarContent)
      } else {
        clearProgressBar()
      }
    }
  }, [isPlayerReady, progressBarContent, setProgressBar, clearProgressBar, shouldRenderMobile])

  useEffect(() => {
    setProgressBarIfNeeded()
    // Clear the progress bar when the component unmounts
    return () => {
      if (shouldRenderMobile) clearProgressBar()
    }
  }, [setProgressBarIfNeeded, clearProgressBar, shouldRenderMobile])

  if (shouldRenderMobile) {
    // On mobile, do not render the progress bar here
    return (
      <Transition
        data-testid="player-controls"
        className={`absolute bottom-0 flex w-full items-center gap-4 bg-gentle-gradient p-4 ${
          isPlayerReady ? 'z-[51]' : 'z-0'
        }`}
        show={showPlayerControls || showVolumeControls}
        {...transitionProps}
      >
        {/* Other controls excluding the progress bar */}
        <div className="flex w-full gap-4">
          <button aria-label={playing ? 'Pause' : 'Play'} className="focus:outline-none" onClick={onTogglePlayAndTrack}>
            <Transition show={showPlayPauseButton} {...transitionProps}>
              {playing ? <IconPause className="size-6 text-white" /> : <IconPlay className="size-6 text-white" />}
            </Transition>
          </button>
          {/* Additional controls */}
        </div>
        {/* Volume controls, etc. */}
      </Transition>
    )
  }
  // On desktop, render as usual
  return (
    <Transition
      data-testid="player-controls"
      className={`absolute bottom-0 flex w-full items-center gap-4 bg-gentle-gradient p-4 ${
        isPlayerReady ? 'z-30' : 'z-0'
      }`}
      show={showPlayerControls || showVolumeControls}
      {...transitionProps}
    >
      {/* Full controls including progress bar */}
      <div className="flex w-full gap-4">
        <button aria-label={playing ? 'Pause' : 'Play'} className="focus:outline-none" onClick={onTogglePlayAndTrack}>
          <Transition show={showPlayPauseButton} {...transitionProps}>
            {playing ? <IconPause className="size-6 text-white" /> : <IconPlay className="size-6 text-white" />}
          </Transition>
        </button>
        {/* Time display */}
        <Transition show={showTimeDisplay} {...transitionProps}>
          <div className="flex items-center gap-2">
            <p className="text-white">{formattedCurrentTime}</p>
            <p className="text-white">/</p>
            <p className="text-white">{formattedDuration}</p>
          </div>
        </Transition>
        {/* Progress bar */}
        <Transition show={showProgressBar} {...transitionProps} className="flex grow items-center">
          {progressBarContent}
        </Transition>
      </div>
      {/* Volume controls */}
      <Transition show={showVolumeControls} {...transitionProps}>
        {isGIF ? (
          <div className="relative flex h-10 flex-row items-center justify-between rounded-3xl p-2">
            <IconGIF className="peer cursor-pointer opacity-50 hover:opacity-100" />
            <span className="invisible absolute -top-14 left-1/2 mt-1 w-fit -translate-x-1/2 scale-50 whitespace-nowrap rounded bg-contrast p-1 px-1.5 py-2 text-center text-xs font-normal text-black opacity-0 transition-all duration-150 peer-hover:scale-100 peer-hover:opacity-100 md:peer-hover:visible">
              Video doesn&#39;t have <br />
              sound
            </span>
          </div>
        ) : (
          <div
            className={`relative flex size-10 flex-row items-center justify-between gap-0 rounded-3xl bg-transparent p-2 transition-width duration-500`}
          >
            <button
              className="mr-2 flex size-6 items-center justify-center focus:outline-none"
              onClick={() => onMute()}
            >
              {isMuted ? <IconUnmute className="size-6 text-white" /> : <IconSpeaker className="size-6 text-white" />}
            </button>
          </div>
        )}
      </Transition>
      {screenfull.isEnabled && (
        <button aria-label="Toggle Full Screen" className="focus:outline-none" onClick={handleFullScreen}>
          <Transition show={showPlayerControls} {...transitionProps}>
            {screenfull.isFullscreen ? (
              <IconExitFullScreen className="size-6 text-white" />
            ) : (
              <IconFullScreen className="size-6 text-white" />
            )}
          </Transition>
        </button>
      )}
    </Transition>
  )
}

import React, { createContext, useContext, useMemo, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

export interface IVideoPlayerContextProps {
  wasEverPaused: boolean
  playing: boolean
  buffering: boolean
  isHovered: boolean
  isGrabbing: boolean
  showMuteAction: boolean
  seeking: boolean
  played: number
  duration: number
  currentTime: number
  wasPlayingBeforeSeeking: boolean
  didPauseForDrag: boolean
  lastInteractionTime: number | null
  isPlayerReady: boolean
  playerRef: React.MutableRefObject<ReactPlayer | null>

  setDidPauseForDrag: React.Dispatch<React.SetStateAction<boolean>>
  setWasPlayingBeforeSeeking: React.Dispatch<React.SetStateAction<boolean>>
  setIsGrabbing: React.Dispatch<React.SetStateAction<boolean>>
  setWasEverPaused: React.Dispatch<React.SetStateAction<boolean>>
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>
  setBuffering: React.Dispatch<React.SetStateAction<boolean>>
  setIsHovered: React.Dispatch<React.SetStateAction<boolean>>
  setShowMuteAction: React.Dispatch<React.SetStateAction<boolean>>
  setSeeking: React.Dispatch<React.SetStateAction<boolean>>
  setPlayed: React.Dispatch<React.SetStateAction<number>>
  setDuration: React.Dispatch<React.SetStateAction<number>>
  setCurrentTime: React.Dispatch<React.SetStateAction<number>>
  setLastInteractionTime: React.Dispatch<React.SetStateAction<number | null>>
  setIsPlayerReady: React.Dispatch<React.SetStateAction<boolean>>
}

export const VideoPlayerContext = createContext<IVideoPlayerContextProps | undefined>(undefined)

export const VideoPlayerContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Boolean states
  const [wasEverPaused, setWasEverPaused] = useState<boolean>(false)
  const [playing, setPlaying] = useState<boolean>(true)
  const [buffering, setBuffering] = useState<boolean>(false)
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [showMuteAction, setShowMuteAction] = useState<boolean>(false)
  const [seeking, setSeeking] = useState<boolean>(false)
  const [isGrabbing, setIsGrabbing] = useState<boolean>(false)
  const [wasPlayingBeforeSeeking, setWasPlayingBeforeSeeking] = useState<boolean>(false)
  const [didPauseForDrag, setDidPauseForDrag] = useState<boolean>(false)
  const [lastInteractionTime, setLastInteractionTime] = useState<number | null>(null)
  const [isPlayerReady, setIsPlayerReady] = useState<boolean>(false)

  // Number states
  const [played, setPlayed] = useState<number>(0)
  const [duration, setDuration] = useState<number>(0)
  const [currentTime, setCurrentTime] = useState<number>(0)

  // Ref to maintain the active playerRef
  const playerRef = useRef<ReactPlayer | null>(null)

  const value: IVideoPlayerContextProps = useMemo(
    () => ({
      wasEverPaused,
      lastInteractionTime,
      playing,
      buffering,
      isHovered,
      showMuteAction,
      didPauseForDrag,
      seeking,
      played,
      duration,
      currentTime,
      isGrabbing,
      isPlayerReady,
      playerRef,
      setIsGrabbing,
      setWasEverPaused,
      wasPlayingBeforeSeeking,
      setWasPlayingBeforeSeeking,
      setPlaying,
      setBuffering,
      setIsHovered,
      setDidPauseForDrag,
      setShowMuteAction,
      setSeeking,
      setPlayed,
      setDuration,
      setCurrentTime,
      setLastInteractionTime,
      setIsPlayerReady,
    }),
    [
      wasEverPaused,
      lastInteractionTime,
      playing,
      buffering,
      isHovered,
      showMuteAction,
      didPauseForDrag,
      seeking,
      played,
      duration,
      currentTime,
      isGrabbing,
      isPlayerReady,
      playerRef,
      setIsGrabbing,
      setWasEverPaused,
      wasPlayingBeforeSeeking,
      setWasPlayingBeforeSeeking,
      setPlaying,
      setBuffering,
      setIsHovered,
      setDidPauseForDrag,
      setShowMuteAction,
      setSeeking,
      setPlayed,
      setDuration,
      setCurrentTime,
      setLastInteractionTime,
      setIsPlayerReady,
    ]
  )

  return <VideoPlayerContext.Provider value={value}>{children}</VideoPlayerContext.Provider>
}

export const useVideoPlayerContext = (): IVideoPlayerContextProps => {
  const result = useContext(VideoPlayerContext)
  if (!result) {
    throw new Error('Context used outside of its Provider!')
  }
  return result
}

import { Notifier } from '@airbrake/browser'
import { clone } from './JSONUtils'
import runtimeConfig from './RuntimeConfig'

const airbrakeConfig = clone(runtimeConfig().publicRuntimeConfig.airbrake)

const notifier = new Notifier({
  ...airbrakeConfig,
  performanceStats: false,
  environment: runtimeConfig().publicRuntimeConfig.nodeEnv,
  remoteConfig: false,
  queueStats: false,
  queryStats: false,
})

export default notifier

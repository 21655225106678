export const formatTime = (seconds: number): string => {
  // Constants
  const SECONDS_TO_MILLISECONDS_FACTOR = 1000
  const ISO_TIME_START_POSITION = 11
  const ISO_TIME_LENGTH = 8
  const HOUR_REMOVAL_POSITION = 3

  // Convert seconds to milliseconds
  const MILLISECONDS = seconds * SECONDS_TO_MILLISECONDS_FACTOR

  // Convert milliseconds to ISO string and extract time part
  const ISOTimeString = new Date(MILLISECONDS).toISOString().substr(ISO_TIME_START_POSITION, ISO_TIME_LENGTH)

  // Check if the hour part is "00", and if so, remove it
  const HOUR_PREFIX = '00:'
  return ISOTimeString.startsWith(HOUR_PREFIX) ? ISOTimeString.substr(HOUR_REMOVAL_POSITION) : ISOTimeString
}

import throttle from 'lodash.throttle'
import { useCallback, useMemo, useRef } from 'react'

import airbrake from '@lib/airbrakeBrowser'
import { stringifyCircularObject } from '@lib/JSONUtils'
import logger from '@lib/logger'
import { useVideoPlayerContext } from '@pages/url/components/VideoPlayerContext'

const MOVEMENT_THROTTLE = 500
export const useVideoPlayerEvents = (): IVideoPlayerEvents => {
  const {
    duration,
    seeking,
    setPlayed,
    setDuration,
    setCurrentTime,
    setBuffering,
    setIsHovered,
    setLastInteractionTime,
    setPlaying,
  } = useVideoPlayerContext()

  // Video Playback related handlers
  const onDuration = useCallback(
    (durationValue: number) => {
      setDuration(durationValue)
    },
    [setDuration]
  )

  interface ProgressState {
    played: number
    loaded: number
    playedSeconds: number
  }

  const prevPlayedSecondsRef = useRef<number>(0)

  const onProgress = useCallback(
    (state: ProgressState) => {
      const isLiveStream = !Number.isFinite(duration)
      const PLAYED_DIGIT = 3
      if (!seeking) {
        setPlayed(parseFloat(state.played.toFixed(PLAYED_DIGIT)))
        if (isLiveStream) {
          const delta = state.playedSeconds - prevPlayedSecondsRef.current
          if (delta >= 0) {
            setCurrentTime((curr: number) => curr + delta)
          }
        } else {
          setCurrentTime(state.playedSeconds)
        }
        prevPlayedSecondsRef.current = state.playedSeconds
      }
    },
    [seeking, setPlayed, setCurrentTime, duration]
  )

  const onError = useCallback(
    (errorType: string, error: Error | unknown | undefined, _hls: object) => {
      if (error) {
        void airbrake.notify(typeof error === 'object' ? stringifyCircularObject(error) : error).catch(() => {})
      }
      logger.error(`PlaybackError: ${errorType}`, error)
      // Pause the video if there's an error so that the user can interact with
      // the DOM in order to retry playback.
      setPlaying(false)
    },
    [setPlaying]
  )

  // Buffer related handlers
  const onBuffer = useCallback(() => {
    setBuffering(true)
  }, [setBuffering])

  const onBufferEnd = useCallback(() => {
    setBuffering(false)
  }, [setBuffering])

  // Mouse interaction handlers
  const onMouseEnter = useCallback(() => {
    setIsHovered(true)
  }, [setIsHovered])

  const onMouseLeave = useCallback(() => {
    setIsHovered(false)
  }, [setIsHovered])

  const onInteraction = useMemo(
    () =>
      throttle(
        () => {
          setLastInteractionTime(Date.now())
        },
        MOVEMENT_THROTTLE,
        { trailing: false }
      ),
    [setLastInteractionTime]
  )
  return {
    onDuration,
    onProgress,
    onError,
    onBuffer,
    onBufferEnd,
    onMouseEnter,
    onMouseLeave,
    onInteraction,
  }
}

export interface IVideoPlayerEvents {
  onDuration: (durationValue: number) => void
  onProgress: (state: { played: number; loaded: number; playedSeconds: number }) => void
  onError: (source: string, error: Error | unknown, object: object) => void
  onBuffer: () => void
  onBufferEnd: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
  onInteraction: () => void
}

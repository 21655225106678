import { RefObject, useCallback } from 'react'
import ReactPlayer from 'react-player'

import useKeyboardShortcut from './useKeyboardShortcut'

export const useVideoPlayerKeyboard = (
  handleMute: () => void,
  handlePlayPause: () => void,
  playerRef: RefObject<ReactPlayer>,
  duration: number
) => {
  const SEEK_AMOUNT_SECONDS = 5

  const handleKeyboardSpaceKey = useCallback(() => {
    handlePlayPause()
  }, [handlePlayPause])

  const handleKeyboardMKey = useCallback(() => {
    handleMute()
  }, [handleMute])

  const handleKeyboardArrowRight = useCallback(() => {
    if (!playerRef.current) return
    const currentTime = playerRef.current.getCurrentTime()
    const newTime = Math.min(currentTime + SEEK_AMOUNT_SECONDS, duration)
    playerRef.current.seekTo(newTime, 'seconds')
  }, [playerRef, duration])

  const handleKeyboardArrowLeft = useCallback(() => {
    if (!playerRef.current) return
    const currentTime = playerRef.current.getCurrentTime()
    const newTime = Math.max(currentTime - SEEK_AMOUNT_SECONDS, 0)
    playerRef.current.seekTo(newTime, 'seconds')
  }, [playerRef])

  useKeyboardShortcut({
    keys: {
      m: handleKeyboardMKey,
    },
    codes: {
      Space: handleKeyboardSpaceKey,
      ArrowRight: handleKeyboardArrowRight,
      ArrowLeft: handleKeyboardArrowLeft,
    },
  })
}

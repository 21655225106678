import { DependencyList, useEffect, useRef } from 'react'

const DEFAULT_DEBOUNCE_DELAY = 50

interface UseDebouncedEffectOptions {
  leading?: boolean
  trailing?: boolean
}

export const useDebouncedEffect = (
  effect: () => void,
  deps: DependencyList,
  delay: number = DEFAULT_DEBOUNCE_DELAY,
  options: UseDebouncedEffectOptions = {}
) => {
  const { leading = false, trailing = true } = options
  const isFirstCallRef = useRef(true)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const cleanupRef = useRef<(() => void) | undefined>(undefined)

  useEffect(() => {
    const invokeEffect = () => {
      const cleanup = effect()
      if (typeof cleanup === 'function') {
        cleanupRef.current = cleanup
      }
      isFirstCallRef.current = false
    }

    if (isFirstCallRef.current && leading) {
      invokeEffect()
    } else if (trailing) {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
      timerRef.current = setTimeout(() => {
        invokeEffect()
        timerRef.current = null
      }, delay)
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }

      if (cleanupRef.current) {
        cleanupRef.current()
        cleanupRef.current = undefined
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export default useDebouncedEffect

import { RefObject, useCallback } from 'react'
import ReactPlayer from 'react-player'

import { useVideoPlayerContext } from '@pages/url/components/VideoPlayerContext'
import { setHasEverUnmuted, setIsMuted } from '@redux/slices/videoSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'

export const useVideoPlayerControls = (playerRef: RefObject<ReactPlayer>): IVideoPlayerControls => {
  const dispatch = useAppDispatch()
  const { isMuted, hasEverUnmuted } = useAppSelector(state => state.video)
  const {
    duration,
    played,
    didPauseForDrag,
    wasPlayingBeforeSeeking,
    setDidPauseForDrag,
    setWasPlayingBeforeSeeking,
    setIsGrabbing,
    setSeeking,
    setPlayed,
    setCurrentTime,
    setPlaying,
  } = useVideoPlayerContext()
  // Play/Pause related functions
  const onPlayPause = useCallback(() => {
    setPlaying(prev => !prev)
  }, [setPlaying])

  const onPlay = useCallback(() => {
    setPlaying(true)
  }, [setPlaying])

  const onPause = useCallback(() => {
    setPlaying(false)
  }, [setPlaying])

  const onEnded = useCallback(() => {
    onPause()
  }, [onPause])

  // Volume related functions
  const onMute = useCallback(() => {
    if (!hasEverUnmuted) {
      dispatch(setHasEverUnmuted(true))
    }
    dispatch(setIsMuted(!isMuted))
  }, [dispatch, isMuted, hasEverUnmuted])

  const PLAYED_DIGIT = 3
  const onSeekChange = useCallback(
    (time: number) => {
      const played = time / duration
      setCurrentTime(time)
      setPlayed(parseFloat(played.toFixed(PLAYED_DIGIT)))
    },
    [duration, setCurrentTime, setPlayed]
  )

  const onSeekMouseDown = useCallback(() => {
    setIsGrabbing(true)
    setSeeking(true)
  }, [setIsGrabbing, setSeeking])

  const onSeekMouseUp = useCallback(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(played)
    }
    if (didPauseForDrag) {
      setDidPauseForDrag(false)
    }

    if (wasPlayingBeforeSeeking) {
      setWasPlayingBeforeSeeking(false)
      onPlayPause() // Resume playback if the video was playing before seeking
    }

    setIsGrabbing(false)
    setSeeking(false)
  }, [
    played,
    playerRef,
    didPauseForDrag,
    setDidPauseForDrag,
    wasPlayingBeforeSeeking,
    setWasPlayingBeforeSeeking,
    setIsGrabbing,
    setSeeking,
    onPlayPause,
  ])

  return {
    onPlayPause,
    onPause,
    onPlay,
    onMute,
    onSeekChange,
    onSeekMouseDown,
    onSeekMouseUp,
    onEnded,
  }
}

export interface IVideoPlayerControls {
  onPause: () => void
  onPlay: () => void
  onPlayPause: () => void
  onMute: () => void
  onSeekChange: (time: number) => void
  onSeekMouseDown: () => void
  onSeekMouseUp: (e: React.MouseEvent) => void
  onEnded: () => void
}

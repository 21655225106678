import { RefObject, useCallback, useEffect } from 'react'
import ReactPlayer from 'react-player'

// Adds additional browser-specific fields that may be present in the media element.
type HTMLVideoElementCompat = HTMLVideoElement & {
  mozHasAudio?: boolean
  webkitAudioDecodedByteCount?: number
  audioTracks?: unknown[]
}

export const getVideoElement = (playerRef: RefObject<ReactPlayer>): HTMLVideoElementCompat | undefined => {
  const internalPlayer = playerRef.current?.getInternalPlayer()
  if (internalPlayer && internalPlayer instanceof HTMLVideoElement) return internalPlayer as HTMLVideoElementCompat
}

const PROVIDERS_WITH_AUDIO = new Set(['youtube', 'vimeo', 'soundcloud', 'twitch'])
export const useAudioPresence = (
  playerRef: React.RefObject<ReactPlayer>,
  isPlayerReady: boolean,
  provider: string,
  setIsAudioPresent: React.Dispatch<React.SetStateAction<boolean | null>>
) => {
  const checkAudio = useCallback(
    (element?: HTMLVideoElementCompat) => {
      const audioPresent =
        Boolean(element?.mozHasAudio) ||
        Boolean(element?.webkitAudioDecodedByteCount) ||
        Boolean(element?.audioTracks?.length)
      setIsAudioPresent(audioPresent)
    },
    [setIsAudioPresent]
  )

  const handleLoadedData = useCallback(() => {
    const videoElement = getVideoElement(playerRef)
    if (videoElement) {
      checkAudio(videoElement)
    }
  }, [checkAudio, playerRef])

  useEffect(() => {
    const videoElement = getVideoElement(playerRef)
    if (videoElement && isPlayerReady) {
      if (PROVIDERS_WITH_AUDIO.has(provider)) {
        return setIsAudioPresent(true)
      }
      if (videoElement.readyState >= HTMLMediaElement.HAVE_CURRENT_DATA) {
        // If the video already has enough data at this point, then the 'loadeddata' event won't get triggered.
        // In this case, we are manually handling the event.
        // Bug: https://app.asana.com/0/1203193565524415/1205608653970557
        handleLoadedData()
      } else {
        videoElement.addEventListener('loadeddata', handleLoadedData)
      }
    }

    return () => {
      videoElement?.removeEventListener('loadeddata', handleLoadedData)
    }
  }, [playerRef, provider, setIsAudioPresent, isPlayerReady, checkAudio, handleLoadedData])
}

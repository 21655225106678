import Long from 'long'

import { commands, globals } from '@lib/messages/protobuf'
import { IntoUrl } from '@models/IntoUrl'
import MixMix from '@services/MixMix'
import { broadcastTrackingMessage } from '../broadcast/trackingMessage'

import CommandContext = commands.CommandContext

const {
  commands: { trackContentPause },
} = MixMix
const { TrackContentPause } = commands
interface ITrackContentPause {
  userId: number | undefined
  url: IntoUrl | undefined
  replayCount: number
  contentTimeTotalMsec: number
  perceivedMediaType: globals.MediaType
  timeOffsetMsec: number
}

export const sendTrackContentPause = ({
  userId,
  url,
  perceivedMediaType,
  contentTimeTotalMsec,
  timeOffsetMsec,
  replayCount,
}: ITrackContentPause) => {
  const { url_id: urlId } = url ?? {}

  if (!urlId) return

  const createMessage = (context: CommandContext) => {
    const hasDuration = Number.isFinite(contentTimeTotalMsec)
    return new TrackContentPause({
      urlId: Long.fromString(urlId),
      userId: userId ? Long.fromNumber(userId) : null,
      autoplay: false,
      replayCount,
      contentTimeTotalMsec: hasDuration ? Long.fromNumber(Math.trunc(contentTimeTotalMsec)) : null,
      perceivedMediaType,
      context,
      timeOffsetMsec: Math.trunc(timeOffsetMsec),
    })
  }

  const initialTrackingConfig = trackContentPause({})
  void broadcastTrackingMessage('TrackContentPause', initialTrackingConfig, createMessage).catch(() => {})
}
